<template>
  <b-modal
    id="section-modal"
    ref="section"
    title="Tạo section mới"
    @hidden="resetModal"
    @ok="submit"
    style="min-width: 800px"
  >
    <b-container fluid class="p-0">
      <form ref="form-group" @submit="submit">
        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <basic-input
                  label="Tiêu đề section"
                  placeholder="Nhập tiêu đề section"
                  name="courseName"
                  :value.sync="filter.name"
                  :required="true"
                ></basic-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <basic-select
                  label="Thuộc bài học"
                  :options="[]"
                  placeholder="LS.001 - Bệnh đái tháo đường là gì, chẩn đoán như thế nào?"
                  name="wards"
                  :value.sync="form.emotionCategoryId"
                  :solid="false"
                  :allowEmpty="false"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <basic-select
                  label="Thuộc khoá học"
                  :options="[]"
                  placeholder="MD.001 - Tổng quan về bệnh đái tháo đường"
                  name="wards"
                  disabled
                  :value.sync="form.emotionCategoryId"
                  :solid="false"
                  :allowEmpty="false"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-col>
                <basic-text-editors
                  label="Nội dung"
                  placeholder="Biên soạn nội dung section"
                  name="content"
                  :value.sync="form.content"
                >
                </basic-text-editors>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </form>
    </b-container>
    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button class="btn btn-success ml-3" type="submit" @click="submit">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'SectionModal',
  components: {},
  data() {
    return {
      form: {
        content: '',
      },
      rendered: false,
      validationState: {},
      error: {},
      filter: {},
      groupFoods: [
        { name: 'Banh canh', id: 1 },
        { name: 'Hu tiu', id: 2 },
        { name: 'Com', id: 3 },
        { name: 'Banh tran tron', id: 4 },
      ],
    };
  },
  computed: {
    customStrings: {
      get() {
        return {
          drag: `<div style="
            height: 28px;
            width: 28px;
            background-color: #fff;
            box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
            margin: auto;"
            class="d-flex align-items-center justify-content-center rounded-circle">
            <span>
            <img src="/media/svg/icons/Neolex/Basic/edit-2.svg" alt="Edit Icon" class="svg-icon" width="14px" height="14px" />
            </span>
            </div>`,
          change: 'Đổi hình ảnh',
          remove: 'Xóa hình ảnh',
        };
      },
    },
  },
  methods: {
    openStaffPopup() {},
    submit() {},
    resetModal() {},
  },
};
</script>

<style lang="scss">
#section-modal {
  .modal-dialog {
    max-width: 80%;
  }
}
.label-required:after {
  content: '*';
  color: red;
}
</style>
<style lang="scss" scoped>
.mh-500 {
  min-height: 500px;
}
.picture-input-container {
  .picture-input {
    .preview-container {
      background: #ffffff;
      border: 3px solid #ffffff;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 6px;

      .picture-preview {
        background-color: #f3f6f9 !important;
      }

      .picture-inner {
        border: none;
      }
    }
  }
}
</style>
